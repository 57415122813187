<template>
  <div class="p-grid">
    <div class="p-col-12">
      <template v-if="loading || !importResult">
        <ProgressBar mode="indeterminate"/>
      </template>
      <template v-else>
        <div v-if="importResult.count === 0 && importResult.tmpCount === 0" class="card p-fluid">
          <InlineMessage class="p-col-12 p-mt-2 p-mb-2" severity="warn">
            Let op! Producten die niet in de csv staan, worden verwijderd. Ook uit de bestellijsten.
          </InlineMessage>
          <div class="p-field p-grid">
            <label for="file" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">CSV Bestand</label>
            <div class="p-col-12 p-md-10">
              <FileUpload
                  mode="basic"
                  name="file"
                  :url="getUploadUrl()"
                  accept="text/csv"
                  :maxFileSize="10000000"
                  @upload="onUpload"
                  @error="onError"
                  @before-send="beforeUpload"
              />
            </div>
            <InlineMessage v-if="error" class="p-col-12 p-mt-2 p-mb-2" severity="error" v-html="error"/>
          </div>
        </div>
        <div v-if="importResult.count === 0 && importResult.tmpCount > 0" class="card">
          <div class="p-field p-grid">
            <div class="p-col-12">
              <h5>Er staan <b>{{ importResult.tmpCount }}</b> producten klaar voor synchronisatie</h5>
            </div>
            <div class="p-col-12 p-mt-4">
              <Button label="Start syncing" icon="pi pi-check" class="p-button-success p-mr-2" @click="startSync"/>
              <Button label="New file" icon="pi pi-trash" class="p-button-danger" @click="cancelSync"/>
            </div>
          </div>
        </div>
        <div v-if="importResult.count > 0 && importResult.tmpCount > 0" class="card">
          <div class="p-col-12">
            <h5>Importing...</h5>
          </div>
          <ProgressBar :value="getProgressPercentage()">
            Nog {{importResult.count}} producten: {{getProgressPercentage()}}%
          </ProgressBar>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import ProductService from '../../service/ProductService'

export default {
  data() {
    return {
      loading: false,
      error: null,
      importResult: null,
      deleteMissingProducts: false,
      intervalId: null
    }
  },
  productService: null,
  created() {
    this.productService = new ProductService()
  },
  mounted() {
    this.updateImportStatus()
  },
  beforeUnmount() {
    clearInterval(this.intervalId)
  },
  methods: {
    updateImportStatus() {
      this.productService.getImportResult().then(data => {
        this.importResult = data
        this.loading = false

        if (this.intervalId && this.importResult.count === 0) {
          clearInterval(this.intervalId)
        } else if (!this.intervalId && this.importResult.count > 0 && this.importResult.tmpCount > 0) {
          this.intervalId = setInterval(() => {
            this.updateImportStatus()
          }, 5000)
        }
      })
    },
    getUploadUrl() {
      return `${process.env.VUE_APP_BASE_URL}/admin/product/import`
    },
    onUpload() {
      this.updateImportStatus()
    },
    onError(event) {
      const res = JSON.parse(event.xhr.response)
      this.error = res.msg
      this.loading = false
    },
    beforeUpload(event) {
      this.error = null
      this.loading = true

      event.xhr.setRequestHeader('X-AUTH-TOKEN', sessionStorage.getItem('apiToken'))
    },
    startSync() {
      this.productService.startImport().then(() => {
        this.updateImportStatus()
      })
    },
    getProgressPercentage() {
      return Math.round(((this.importResult.tmpCount - this.importResult.count) / this.importResult.tmpCount) * 100)
    },
    cancelSync() {
      this.loading = true
      this.productService.cancelImport().then(() => {
        this.productService.getImportResult().then(data => {
          this.importResult = data
          this.loading = false
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
table {
  &.sync-products {
    border-spacing: 0;

    tr th, tr td {
      border: 1px solid #000;
      padding: 5px;
    }

  }
}
</style>
